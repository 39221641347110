.root {
  display: flex;
  flex-direction: column;

  .panel {
    margin-top: 26px;
    padding: 0 24px 34px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
    padding: 30px 88px 0;
    max-width: 800px;
  }

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
  }
}
