.root {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }
}

.fieldWithCurrency {
  display: flex;
  gap: 4px;

  .select {
    min-width: 130px;
  }
}
