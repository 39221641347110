.container {
  padding-top: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;

  &:not(:last-child) {
    border-bottom: 1px solid var(--system-borders);
  }

  .label {
    max-width: 115px;
    width: 100%;
    text-align: left;
    margin-right: 25px;
    color: var(--body-light);
  }

  .value {
    flex: 1;
    color: var(--body);
  }
}
