.root {
  display: flex;
  flex: 0.5;

  .status {
    display: flex;
    align-items: center;
    padding: 0 14px 0 8px;
  }

  .field {
    flex: 0.5;
  }

  .placeholder {
    font-weight: 400;
    color: var(--error);
  }

  .arrow {
    display: flex;
    align-items: center;
    padding: 0 16px;

    svg {
      transform: rotate(-90deg);
      color: var(--subtitle);
    }
  }

}

