.root {
  display: flex;
  flex-direction: column;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;

  }

  .phone {
    input {
      flex: 1;
      color: var(--body-light);
    }

    ul {
      width: 346px;
    }
  }
}
