.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: var(--system-background);
  border: 1px solid var(--system-background);
  border-radius: 8px;
  cursor: pointer;

  svg {
    color: var(--primary);
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover {
    border-color: var(--primary);
  }
}

.small {
  width: 24px;
  height: 24px;
}

.medium {
  width: 32px;
  height: 32px;
}

.large {
  width: 40px;
  height: 40px;
}

.rounded {
  border-radius: 50%;
}

.warning {
  border: none;

  svg {
    color: var(--warning);
    background: none;
  }
}
