.root {
  position: relative;

  .infiniteScroll {
    padding: 24px 0;
    overflow: unset !important;
  }

  .table {
    overflow-x: unset;
  }
}
