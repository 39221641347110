.root {
  display: grid;
  align-items: center;
  margin-top: 24px;

  .table {
    --field-min-width: 200px;
    --first-field-min-width: 240px;
    .header {
      font-weight: 600;
      color: var(--headings1);
      background: var(--secondary-light);
      align-items: center;
      font-size: 14px;
      width: calc(100vw - 194px);

      tr {

        th {
          min-width: var(--field-min-width);
        }

        .tableCell {
          padding: 16px 0 16px 12px;
          white-space: unset;
          color: var(--body);

          button {
            margin-left: 14px;
          }
        }

        > :first-child {
          background: var(--secondary-light);
          position: sticky;
          z-index: 1;
          left: 0;
          padding-left: 12px;
          min-width: var(--first-field-min-width);

          &::after {
            content: "";
            position: absolute;
            right: 0;
            height: 100%;
            border-right: 1px solid var(--system-borders);
            top: 0;
          }

          span, label {
            padding: 0 0 0 21px;
          }

          button {
            margin: 0 16px;
          }
        }

        > :last-child {
          background: var(--secondary-light);
          position: sticky;
          z-index: 2;
          right: 0;
          padding: 0;
          min-width: 96px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            border-left: 1px solid var(--system-borders);
            top: 0;
          }

          span {
            padding: 19px 22px;
          }


          button {
            margin: 0;
          }
        }
      }

      .separator {
        margin: 0;
      }
    }

    .headerWithoutActions {

      tr {
        > :first-child {
          span {
            padding: 0 0 0 4px;
          }
        }
        > :last-child {
          position: relative;
          min-width: var(--field-min-width)!important;
          padding: 16px 0 16px 12px;
          &::before {
            border: 0!important;
          }
          span {
            padding: 0;
          }
        }
      }

    }

    .body {

      tr {
        > :first-child {
          background: var(--system-background-light);
          position: sticky;
          z-index:1;
          left:0;
        }
        > :last-child {
          background:var(--system-background-light);
          position: sticky;
          z-index:2;
          right:0;
        }

        td {
          padding: 20px 12px;
          min-width: var(--field-min-width);

          &:first-of-type {
            span {
              svg {
                cursor: pointer;
                vertical-align: middle;
                color: var(--warning);
                margin-left: 14px;
              }
            }
            padding: 0;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              height: 100%;
              border-right: 1px solid var(--system-borders);
              top: 0;
            }

            label {
              span {
                padding: 0 0 0 24px;
                margin-left: 12px;
              }
            }
            span, label {
              float: left;
              padding: 18px 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 340px;
            }
          }
          &:last-of-type {
            min-width: auto;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              height: 100%;
              border-left: 1px solid var(--system-borders);
              top: 0;
            }
            padding: 0;
          }
        }
      }
    }

    .bodyWithoutActions {

      tr {
        > :last-child {
          position: relative;
      }}

      td {
        &:last-of-type  {
          &::before {
            border: 0!important;
          }
        }
      }

    }
  }
}
