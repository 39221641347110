.root {
  display: flex;
  flex-direction: column;

  .subTitle {
    color: var(--body-light);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .subTitleModal {
    color: var(--color-text-grey);
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 12px;
  }

  .timerWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .timer {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      gap: 4px;
      color: var(--body-light);
    }

    .resendCode {
      color: var(--primary);
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      background: none;
      border: none;
      padding: 0;
    }
  }

  .inputWrapper {
    input {
      color: var(--body-light);
    }
  }
}
