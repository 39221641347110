.item {
  display: flex;
  align-items: center;
  gap: 24px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 240px;
    word-break: break-word;

    .name {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--body);
    }

    .email {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--body-light);
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-grow: 1;
  }

  .role {
    display: flex;
    align-items: center;
    gap: 8px;

    & > p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--body);
    }
  }
}

.loginButton {
    display: flex;
    align-items: center;
    gap: 16px;
}

.statuses {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 11px;
}
