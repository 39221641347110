.root {
  display: flex;
  flex-direction: column;

  .subTitle {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--body-light);
  }
}
