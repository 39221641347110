
.root {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 0 88px 0;
  }

  .subTitle {
    padding-top: 2px;
    font-size: 16px;
    font-weight: 400;
  }

  .warning {
    display: flex;
    gap: 12px;
    color: var(--warning);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    svg {
      margin-top: 6px;
      color: var(--warning);
    }
  }

  .permissionPanel {
    border: 1px solid var(--system-borders);
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
    background: var(--secondary-light);
    padding: 24px;
    border-radius: 8px 8px 0 0;
  }

  .buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    padding: 0 88px;
  }

  .editButtonsWrapper {
    justify-content: flex-end;
  }

  .prevNextButtonsWrapper {
    display: flex;
    gap: 16px;
  }
}

.selectionContent {
  padding: 16px 24px 24px;

  .subtitle {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--subtitle);
  }

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
  }

  .item {
    width: 100%;

    @media (min-width: 1100px) {
      width: calc((100% - 24px) / 2);
    }

    &Title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--body);
    }
  }
}
