.root {
  padding: 0 90px;
}

.title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--body);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body);
}

.subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--subtitle);
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  width: 100%;

  @media (min-width: 1100px) {
    width: calc((100% - 24px) / 2);
  }

  &Title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }
}
