.item {
  display: flex;
  align-items: center;
  gap: 24px;

  & > h3 {
    min-width: 240px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  & > div {
    flex-grow: 1;
  }
}
