.root {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }

  textarea {
    width: inherit;
    min-height: 80px;
    max-height: 300px;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--system-borders);
    font-size: 16px;
    line-height: 28px;
    color: var(--body);
    background-color: var(--system-background-light);
    resize: vertical;
    outline: none;

    &::placeholder {
      line-height: 28px;
      color: var(--body-light);
      opacity: 0.7;
    }
  }

  &Error textarea {
    border: 1px solid var(--error),
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .error {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--error);
  }

  .counter {
    flex: 1 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: var(--body-light);
    opacity: 0.7;
  }
}