.root {
  padding-top: 14px;

  .item {
    display: flex;
    gap: 24px;

    p:first-of-type {
      min-width: 114px;
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      color: var(--body-light);
    }

    p:last-of-type {
      max-height: 200px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--body);
      overflow-y: auto;
      word-break: break-word;
    }
  }
}

.separator {
  margin: 14px 0;
}
