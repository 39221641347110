.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 0 24px 34px;
  }

  .subTitle {
    padding: 30px 78px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--headings2);
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
    padding: 0 88px 0;
  }

  .buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    padding: 0 88px;
  }

  .editButtonsWrapper {
    justify-content: flex-end;
  }
}
