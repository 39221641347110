.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .header {
    font-size: 20px;
    font-weight: 600;
    color: var(--heading1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;
      flex: 0.5;
      gap: 12px;
    }

    button {
      max-width: 152px;
      max-height: 40px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      color: var(--subtitle);
    }
  }

  .separator {
    margin: 18px 0 14px;
  }

  .verticalSeparator {
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .showArchived {
        font-size: 14px;
        color: var(--body);
        font-weight: 600;
        display: flex;
        flex-direction: row;
        gap: 10px;
        span {
          line-height: 24px;
        }
      }

      .search {
        input {
          height: 44px;
        }
      }
      .filter {
        position: relative;
        margin-right: 10px;
        span:last-of-type {
           svg:last-of-type {
             width: 18px;
             position: absolute;
             top: 0;
             right: -6px;
           }
       }
      }
    }

    .pageNumber {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--subtitle);
      font-weight: 500;
    }
  }
}
