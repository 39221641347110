.root {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .form {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;
  }

  .phone {
    input {
      color: var(--color-text-placeholder);
      flex: 1;
    }
  }

}

