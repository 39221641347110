.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .panel {
    position: relative;

    .content {
      padding: 34px 24px;
    }
  }

}
