.root {
  display: flex;
  list-style: none;
}

.item {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: var(--body-light);

  & > a {
    color: var(--body);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.separator {
  margin: 0 10px;
  color: var(--body);
}
