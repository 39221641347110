.root {
  display: flex;
  flex-direction: column;

  .panel {
    margin-top: 26px;
    padding: 0 24px 34px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
    padding: 30px 88px 0;
  }

}
