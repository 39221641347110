.root {
  display: flex;
  flex-direction: column;
  min-height: 184px;

  .panel {
    flex: 1;
    padding: 30px 16px 22px;
    cursor: pointer;
  }

  .icon {
    svg {
      color: var(--primary);
    }
  }

  .title {
    color: var(--body);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 30px;
  }

  .description {
    color: var(--subtitle);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 6px;
  }

  .navLink {
    display: flex;
    flex: 1;
    cursor: pointer;
    text-decoration: unset;
  }

  .disabled {
    opacity: 0.4;
  }
}

