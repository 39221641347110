.root {
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0 8px;
  }

}
