.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0 90px;
}

.label {
  display: flex;
  align-items: center;
  gap: 8px;
}
