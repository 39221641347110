.root {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .qrCodeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    .button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 14px;
      text-decoration: unset;
      font-weight: 600;
      line-height: 24px;
      color: var(--primary);

      &:hover {
        color: var(--primary-dark);
      }
    }

    img {
      width: 216px;
    }
  }

  ul {
    padding-left: 22px;

    span {
      text-transform: capitalize;
    }
  }
}

