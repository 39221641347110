.rc-pagination {
  display: flex;
  gap: 8px;
  margin-top: 80px;

  li {
    min-width: 39px;
    height: 35px;
  }

  &-item {
    background: var(--system-background);
    border-radius: 6px;

    &-active {
      border-color: var(--primary)!important;
      color: var(--primary);
      &:focus {
        a {
          color: var(--primary);
        }
      }
    }


    &:hover {
      border-color: var(--primary);
    }

    a {
      line-height: 35px;
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: var(--body);
      &:hover {
        color: var(--primary);
      }
    }
  }

  &-disabled, .rc-pagination-next, .rc-pagination-prev {
    background: var(--system-background);
    border: 1px solid var(--system-borders);
    border-radius: 6px;
    svg {
      vertical-align: middle;
      margin-top: 3px;
    }
  }

  &-disabled {
    svg {
      color: var(--body-disabled)!important;
    }
  }

  .rc-pagination-next {
    svg {
      color: var(--body);
    }
  }

  .rc-pagination-prev {
    svg {
      color: var(--body);
    }
  }

}
