.root {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
    padding: 0 4px 2px;
  }

  svg {
    color: var(--primary);
  }
}
