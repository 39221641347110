.header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  position: relative;
  .leftPanel {
    max-width: 33%;
    min-width: 500px;
    .leftContent {
      padding: 4px 8px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
      border-radius: 6px;
      background-color: var(--system-background-light);
      gap: 16px;
      display: flex;
      align-items: center;
      margin-left: 100px;
    }
    .hintWrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      min-width: 0;
      .hint {
        top: 150%
      }
    }
    .title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 8px;
      border-radius: 4px;
      &:hover {
        background-color: var(--system-text-background);
        cursor: pointer;
      }
    }
    .status {
      flex: 1;
      cursor: pointer;
    }
  }
  .centerPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    .switcher {
      display: flex;
      padding: 4px;
      border-radius: 6px;
      background-color: var(--system-background-light);
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    }
  }
  .rightPanel {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    .rightContent {
      display: flex;
      gap: 8px;
      align-items: center;
      background-color: var(--system-background-light);
      padding: 4px 16px;
      height: 48px;
      border-radius: 6px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
      .icon {
        background-color: var(--system-background-light);
        border: 0;
        svg {
          color: #363A3A;
        }
      }
      .undoIcon {
        @extend .icon;
        transform: scaleX(-1);
        &.disabled {
          color: #B5B9BA;
        }
      }
      .saveContainer {
        display: flex;
        gap: 8px;
        .checkMarkContainer {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background-color: var(--headings2);
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            color: var(--system-background-light);
          }
        }
        .saveTitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  .divider {
    width: 1px;
    height: 28px;
    background-color: var(--system-borders);
  }
}

