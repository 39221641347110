.root {
  display: flex;
  width: 54px;
  height: 54px;
  border-radius: 8px;
  background-color: var(--system-background);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
