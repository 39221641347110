.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.title {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  color: var(--body);
}
