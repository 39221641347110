.root {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin: 24px 0;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
      color: var(--headings1);
    }
  }
}
