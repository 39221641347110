.root {
  position: fixed;
  height: 100vh;
  width: 328px;
  right: 0;
  background: var(--system-background-light);
  top: 0;
  z-index: 2;
  padding: 0 16px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);

  .header {
    display: flex;
    flex-direction: column;
    padding-top: 64px;

    .menu {

      .list {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin-top: 6px;
      }

      .link {
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 56px;
        color: var(--body);
        text-decoration: none;
        border: 1px solid transparent;
        transition: background-color 300ms ease;
        cursor: pointer;
        padding:  0 8px;
        justify-content: center;

        p {
          font-size: 16px;
        }

        &:hover {
          background-color: var(--primary-light);
        }
      }

      ul {
        display: flex;
        flex-direction: row;

        li {
          flex: 0.5;
        }

        a {
          color: var(--body-light);
          line-height: 36px;
          font-size: 16px;
          font-weight: 700;
        }
      }
      .active {
        color: var(--primary);
        border: none;
        border-radius: unset;
        background: none;
        border-bottom: 2px solid var(--primary);
      }
      .separator {
        margin: -1px 0 0 0;
      }
    }
    .actions {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: space-between;
      padding: 14px 0;
    }
    .headerSeparator {
      margin: 0;
    }
  }

  .contentWrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .content:first-of-type .filter > ul:first-of-type > li {
      margin-left: -32px;

      &:after {
        display: none!important;
      }
    }

    .content, .contentEdit {
      padding-bottom: 288px;
      height: 100%;
    }

    .contentEdit {
      padding-bottom: 210px;
    }

    .scrollContent {
      height: 100%;
      overflow: auto;
    }

    .filter {
      margin-top: 6px;

      .draggingSource {
        opacity: 1;
      }

      .dropTarget {
        opacity: 1;
      }

      ul {
        list-style-type: none;
        --spacing: 24px;
        --radius: 9px;

        li {
          list-style-type: none;
          line-height: 24px;
          padding-top: 8px;
          display: block;
          position: relative;
          padding-left: 36px;

          &:before {
            border-left: 1px solid var(--system-borders);
            content: "";
            left: 48px;
            position: absolute;
            top: 50px;
            height: calc(100% - 44px);
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 12px;
            width: 16px;
            height: 28px;
            border: solid var(--system-borders);
            border-width: 0 0 1px 0;
          }

          div {
            display: flex;
            flex-direction: column;

            label {
              padding: 0 4px 0 40px;
            }
          }

          &:last-child {
            border-color: transparent;
          }
        }
      }

    }

    .buttonsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: flex-start;
      padding: 20px 0 20px;
      position: fixed;
      bottom: 0;
      background: var(--system-background-light);
      width: 100%;

      button {
        min-width: 140px;
      }
    }
  }
}
