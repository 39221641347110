.linkButton {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;

  & svg {
    display: block;
    color: inherit;
  }
}
