.root {
  display: flex;
  flex-direction: column;
  margin: 62px 0;
  width: 100%;
  align-items: center;
  gap: 8px;

  .text {
    text-align: center;
    line-height: 28px;
    color: var(--body);
  }
}

.warning {
  text-align: center;
  .text, svg {
    color: var(--warning);
  }

  .button {
    margin-top: 16px;
  }
}
