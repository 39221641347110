.card {
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
  cursor: pointer;
  transition: background-color 300ms ease;
}

.header.active {
  background-color: var(--secondary-light);
}

.header.active .icon > svg {
  transform: rotate(180deg);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);

  & > svg {
    color: var(--system-background-dark);
    transition: transform 300ms ease;
  }
}

.body {
  min-height: 1px;
  padding: 15px 24px 24px;
}

.collapse {
  position: relative;
  height: 0;
  transition: height 300ms ease;
  overflow: hidden;
}

.collapse.show {
  height: auto;
}
