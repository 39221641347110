.root {
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover .button,
  &.active .button {
    visibility: visible;
    pointer-events: initial;
  }

  .separator {
    margin: 0;
  }
}
