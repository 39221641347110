.root {
  display: none;

  .fullLoader {
    background: var(--body-light);
    opacity: 0.1;
    z-index: 9;
    position: absolute;
    animation-duration: 925ms;
    animation-timing-function: steps(56);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    overflow: hidden;
    min-height: auto;
  }


  .loader {
    font-size: 14px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9;
    color:var(--body-light)
  }
  @keyframes mulShdSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em var(--body-light), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light));
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), 1.8em -1.8em 0 0em var(--body-light), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light));
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), 2.5em 0em 0 0em var(--body-light), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light));
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), 1.75em 1.75em 0 0em var(--body-light), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light));
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), 0em 2.5em 0 0em var(--body-light), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light));
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), -1.8em 1.8em 0 0em var(--body-light), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light));
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), -2.6em 0em 0 0em var(--body-light), -1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light));
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.8em -1.8em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 2.5em 0em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 1.75em 1.75em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), 0em 2.5em 0 0em color-mix(in srgb, var(--body-light) 20%, var(--system-background-light)), -1.8em 1.8em 0 0em color-mix(in srgb, var(--body-light) 50%, var(--system-background-light)), -2.6em 0em 0 0em color-mix(in srgb, var(--body-light) 70%, var(--system-background-light)), -1.8em -1.8em 0 0em var(--body-light);
    }
  }

}

.isPageLoading {
  display: flex;
  justify-content: center;
}
