.header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  .info {
    display: flex;
  }

  .status {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }
}

.close {
  padding: 0;
  background-color: transparent;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body),
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .separator {
    margin: 0;
  }
}

.item {
  width: 100%;

  @media (min-width: 1100px) {
    width: calc((100% - 24px) / 2);
  }
}
