.root {
  box-shadow: 0px 2.36px 3.54px 0px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  padding: 9px;
  background-color: var(--secondary);

  & > svg {
    max-width: 36px;
    max-height: 36px;
  }
}

.content {
  padding: 9px;
}
