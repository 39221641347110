.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 90px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  width: 100%;

  @media (min-width: 1100px) {
    width: calc((100% - 24px) / 2);
  }
}
