.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 90px;
}

.item {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 15px 0;

  &Title {
    width: 240px;
    min-width: 240px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  &Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-grow: 1;
  }

  .status {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--body);
  }
}