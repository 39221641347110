.root {
  display: flex;
  align-items: center;

  .backButton {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary);
    background: none;
    border: none;
    cursor: pointer;

    svg {
      color: var(--primary);
    }
  }
}
