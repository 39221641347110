.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 0 24px 34px;
  }

  .subTitle {
    padding: 30px 78px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--headings2);
  }

  .description {
    padding: 8px 78px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--subtitle);
  }
}
