.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  .addButton {
    padding-left: 30px;
    padding-right: 30px;
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    color: var(--headings1);
  }
}
