.root {
  background-color: var(--system-background);
}

.wrapper {
  display: flex;
  gap: 24px;
}

.sidebar {
  min-width: 204px;
}

.content {
  flex-grow: 1;
}

.createContent {
  flex-grow: 1;
  margin-top: -62px;
}
