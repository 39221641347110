.root {
  background-color: var(--system-background);
}

.wrapper {
  padding: 0;
}

.content {
  margin-top: 16px;
}
