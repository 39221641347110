.root {
  background-color: var(--system-background);

  .title {
    margin: 0 0 26px;
    color: var(--headings1);
  }

  .breadcrumbs {
    margin-bottom: 36px;
  }
}

.wrapper {
  display: flex;
  gap: 24px;
}

.sidebar {
  min-width: 204px;
}

.content {
  flex-grow: 1;
}
