.list {
  display: flex;
  list-style: none;
}

.link {
  position: relative;
  display: block;
  padding: 16px 24px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body-light);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--primary);
    transform: scaleX(0);
    transition: transform 300ms ease, color 300ms ease;
    z-index: 5;
  }
}

.active {
  color: var(--primary);
}

.active::after {
  transform: scaleX(1);
}
