.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
    padding-bottom: 6px;
  }

  input {
    width: inherit;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--system-borders);
    font-size: 16px;
    line-height: 28px;
    color: var(--body);
    background-color: var(--system-background-light);
    outline: none;

    &::placeholder {
      line-height: 28px;
      color: var(--body-light);
      opacity: 0.7;
    }

    &:disabled {
      background-color: var(--system-background);
      cursor: not-allowed;
    }
  }

  &Error input {
    border: 1px solid var(--error),
  }

  .error {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--error);
  }


  :global {
    .react-datepicker {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

      div, h2 {
        color: var(--body);
      }
      h2 {
        font-size: 16px;
        padding: 2px 0 6px 0;
      }
      &[role='dialog'] {
        border: 0;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
      }
      &__header {
        border: none;
        background: var(--secondary-light);

      }
      &__day {
        font-size: 14px;
        font-weight: 500;
        margin: 4px;
        &--selected {
          background: var(--primary);
          color: var(--system-background-light)!important;
        }
        &:hover {
          background: var(--primary);
          color: var(--system-background-light);
        }
        &--disabled {
          color: var(--body-light)!important;
          opacity: 0.5;
          &:hover {
            background: transparent;
            color: var(--body);
          }
        }
        &-names {
          font-weight: 600;
        }
        &-name {
          font-size: 14px;
          margin: 4px;
        }
      }
      &-popper {
        z-index: 10;
        &[data-placement='bottom'] {
          svg {
            color: var(--secondary-light);
            fill: var(--secondary-light);
            stroke: var(--secondary-light);
          }
        }
        &[data-placement='top'] {
          svg {
            stroke: var(--system-background-light);
          }
        }
      }
      &__navigation {
        &-icon {
          &::before {
            border-width: 2px 2px 0 0;
            border-color: var(--body);
          }
        }
        &--next, &--previous {
          top: 9px;
          &:hover {
            span {
              &::before {
                border-color: var(--body);
              }
            }
          }
        }
      }
      &__month {
        margin: 0 12px 8px;
      }
    }
  }
}

