.wrapper {
  display: flex;
  gap: 16px;
}

.user {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &Name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
    text-transform: capitalize;
  }

  &Email {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  .link {
    text-decoration: unset;
  }
}

.role {
  display: table-cell;
  vertical-align: middle;

  .wrapper {
    display: flex;
    gap: 8px;
  }
}

.pillars {
  display: table-cell;
  flex-direction: column;
  gap: 8px;
  align-items: baseline;
  vertical-align: middle;

  .pillar {
    padding: 3px 8px;
    font-size: 14px;
    font-weight: 500;
    color:  var(--body);
    border: 1px solid var(--system-borders);
    border-radius: 6px;
    background-color: var(--system-background-light);
    pointer-events: none;
    margin-bottom: 8px;
  }

  .pillar:last-child {
    margin-bottom: 0;
  }
}


