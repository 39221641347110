.container {
  background-color: var(--system-background-light);
  padding: 9px 16px;
  height: 36px;
  border-radius: 40px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
  align-items: center;
  display: inline-flex;
  gap: 6px;
  p {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    &.text {
      font-weight: 400;
    }
  }
  .list {
    display: inline-flex;
    gap: 20px;
    list-style-type: none;
    li {
      display: flex;
      gap: 8px;
      align-items: center;
      div {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        &.Subsidiary {
          background-color: var(--profit-center-subsidary);
        }
        &.JV {
          background-color: var(--profit-center-jv);
        }
        &.BusinessUnit {
          background-color: var(--profit-center-business-unit);
        }
        &.AssociateSubsidiary {
          background-color: var(--profit-center-associate-sub);
        }
        &.Division {
          background-color: var(--profit-center-division);
        }
      }
    }
    .text {
      text-transform: capitalize;
    }
  }
}
