.root {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
  margin: 0 auto;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}
