.root {
  display: flex;
  align-items: center;
  gap: 24px;

  & > p {
    min-width: 143px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details {
  padding: 0;
  background-color: transparent;
}
