.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 90px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--headings2);

  & > svg {
    color: inherit;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.input {
  max-width: 380px;
}
