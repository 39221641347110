.root {
  display: inline-flex;
  min-width: 44px;
  height: 24px;
}

.root input {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.indicator {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--system-borders);
  border-radius: 24px;
  transition: background-color 300ms ease;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--system-background-light);
    transition: left 300ms ease;
  }
}

.root input:checked + .indicator {
  background-color: var(--primary);
}

.root input:disabled + .indicator {
  opacity: 0.6;
  cursor: not-allowed;
}

.root input:checked + .indicator::after {
  left: calc(100% - 22px);
}
