.item {
  display: flex;
  align-items: center;
  gap: 24px;

  & > h3 {
    min-width: 240px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  & > p {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--body);
  }

  .verified {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--status-active);
  }

  .role {
    display: flex;
    gap: 8px;
    text-transform: capitalize;
  }

  .status {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 45px;
  }
}
