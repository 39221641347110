.root {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    color: var(--headings1);
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin: 24px 0;

    svg {
      color: var(--primary);
    }

    .card {
      min-height: auto;
    }
  }
}
