header {
  display: flex;
  background-color: var(--system-background-light);
  padding: 24px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 0 8px rgba(0,0,0,.6);

  .navigation {
    display: flex;
    gap: 24px;
  }

  .separator {
    height: 100%;
    margin: 0 12px;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 42px;

    .partnerLogo {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }

    .companyLogo {
      width: 138px;
      height: 38px;
      cursor: pointer;
    }
  }
}
