.root {
  display: flex;
  flex-direction: column;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;
  }

  .subTitle {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--body-light);
  }
}
