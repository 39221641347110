@import "react-image-crop/src/ReactCrop.scss";
@import "react-toastify/dist/ReactToastify.css";
@import "theme";
@import "toast";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--system-background);
  span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.authTitle {
  margin: 0 0 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.6px;
  text-align: center;
  color: var(--headings1);
}

#loader path,
#loader rect{
  fill: var(--primary);
}

a {
  margin-top: 8px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary);
  text-decoration: none;

  &:hover {
    color: var(--primary-dark);
  }
}

[class*="FiltersBar_draggingSource_"] [class*="CustomNode_editItem_"] {
  color: var(--system-background-light)!important;
  span {
    color: var(--system-background-light-dark)!important;
    background-color: var(--system-background-light)!important;
  }
  border: 1px dashed var(--system-borders)!important;
}

[class*="FiltersBar_dropTarget"] > div  [class*="CustomNode_editItem_"] {
  border: 1px solid var(--primary)!important;
  color: var(--primary)!important;
}
