.root {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--system-borders);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: var(--system-borders);
  }
}

.table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    white-space: nowrap;
  }

  thead th {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--body-light);
  }

  tbody td {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }
}
