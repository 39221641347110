.root {
  padding: 34px 24px;
  position: relative;
}

.list {
  padding: 0 90px;
  list-style: none;
  position: relative;
}

.modalList {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--body-light);
  padding: 16px;
}

.modalText {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
}
