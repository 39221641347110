.root {

  .dotsLoader {
    margin: 0 auto;
    width: 100%;
    text-align: center;

    div {

      @-webkit-keyframes sk-bouncedelay {
        0%, 80%, 100% { transform: scale(0) }
        40% { transform: scale(1.0) }
      }

      @keyframes sk-bouncedelay {
        0%, 80%, 100% {
          transform: scale(0);
        }

        40% {
          transform: scale(1.0);
        }
      }

      width: 12px;
      height: 12px;
      margin: 4px;
      background-color: var(--system-background-light);
      opacity: 0.4;
      vertical-align: middle;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          -webkit-animation-delay: 0.16s * $i;
          animation-delay: 0.16s * $i;
        }
      }
    }

  }

}
