.root {
  display: flex;
  flex-direction: column;

  .description {
    color: var(--color-text-grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;

    br {
      display: block;
      margin: 4px 0;
    }
  }

}
