.root {

  .title {
    margin: 34px 0;
    font-size: 24px;
    color: var(--headings1);
  }

  .breadcrumbs {
    margin-bottom: 36px;
  }
}

.wrapper {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.content {
  flex-grow: 1;
}
