.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 34px 114px 34px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    .fullRow {
      grid-column: 1 / -1;
      grid-row: 1;
    }
  }

  .asyncSelect {
    svg {
     cursor: pointer;
    }
  }

  .inactiveSelect {
    opacity: 0.5;
  }
}
