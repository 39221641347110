.root {
  display: flex;
  flex-direction: column;
  gap: 22px;

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    gap: 16px;

    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--body);
    }

    span {
      text-transform: capitalize;
    }
  }

}

