.root {
  align-items: flex-start;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 8px;
}

.editRoot {
  height: 36px;
}

.editItem, .activeEditItem {
  display: flex;
  flex-direction: row!important;
  border-radius: 4px;
  border: 1px solid var(--system-background);
  cursor: pointer;

  .area {
    display: flex;
    flex-direction: column;
    writing-mode: vertical-rl;
    background: var(--system-background);
    width: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    border-radius: 4px 0 0 4px;
  }

  .label {
    font-size: 14px;
    padding: 3px 8px;
  }

  &:hover {
    color: var(--primary);
    border: 1px solid var(--primary);
    .area {
      color: var(--primary);
      background: var(--secondary);
    }
  }
}

.activeEditItem {
  color: var(--primary);
  border: 1px solid var(--primary);
  .area {
    color: var(--primary);
    background: var(--secondary);
  }
}

.itemWrapper {
  display: flex;
  flex-direction: row!important;
  gap: 10px;
}

.withoutNesting {
  margin-left: 34px;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear .1s;
  transform: rotate(-90deg);
  svg {
    color: var(--body-disabled);
  }
}

.expandIconWrapper.isOpen {
  transform: rotate(0deg);
}

.isEdit {
  margin-top: 4px;
}


.labelGridItem {
  padding-inline-start: 8px;
}
