.root {
  display: flex;
  flex-direction: column;

  .subTitle {
    font-size: 14px;
    color: var(--body-light);
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .description {
    padding-bottom: 22px;
  }

  .radioLabelWrapper {
    display: flex;
    gap: 8px;
    height: 24px;

    .label {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--body);
    }
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 16px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--system-borders);
    background: var(--system-background-light);
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;
  }
}
