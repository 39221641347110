.root {
  border: 1px solid var(--system-borders);
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
    background: var(--secondary-light);
    padding: 24px;
    border-radius: 8px 8px 0 0;
  }

  .content {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0 88px 0;
    gap: 22px;
    margin: 14px 0;

    .subtitle {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--subtitle);
    }

    .container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
    }

    .item {
      width: 100%;

      @media (min-width: 1100px) {
        width: calc((100% - 24px) / 2);
      }

      &Title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: var(--body);
      }
    }
  }
}
