.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
  max-height: 882px;
  padding: 24px;
  border: 1px solid var(--system-borders);
  border-radius: 6px;
  overflow: hidden;

  .heading1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--headings1);
  }

  .heading2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: var(--headings2);
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--subtitle);
  }

  .body {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--body);
  }

  .primaryButton {
    &:hover {
      box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.3);
      border-color:  rgba(0, 0, 0, 0.3)!important;
    }
  }

}

.previewTitle {
  margin-bottom: 16px;
  color: var(--body);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.logos {
  display: flex;
  gap: 16px;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cards {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .card {
    min-width: 183px;

    &Title {
      margin-bottom: 2px;
      font-size: 9px;
      font-weight: 700;
      line-height: 14px;
      color: var(--body);
    }

    &Text {
      font-size: 8px;
      font-weight: 500;
      line-height: 13px;
      color: var(--subtitle);
    }
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: self-end;
  justify-content: space-between;
  gap: 8px;
  flex-grow: 1;

  & button {
    min-width: 77px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 16px;
}
