.root {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: var(--body);
  }

  .wrapper {
    position: relative;
    width: 100%;
  }

  input {
    width: inherit;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--system-borders);
    font-size: 16px;
    line-height: 28px;
    color: var(--body);
    background-color: var(--system-background-light);
    outline: none;

    &::placeholder {
      line-height: 28px;
      color: var(--body-light);
      opacity: 0.7;
    }

    &:disabled {
      background-color: var(--color-background);
      cursor: not-allowed;
    }
  }

  &Error input {
    border: 1px solid var(--error),
  }

  .error {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--error);
  }
}
