.root {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .link {
    text-decoration: unset;

    button {
      width: 100%;
      border: 1px solid var(--system-borders);
      color: var(--body);
    }
      svg {
        color: var(--body);
      }
  }

  ul {
    font-size: 16px;
    padding: 0 12px;
    font-weight: 400;
    line-height: 28px
  }
}

