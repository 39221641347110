.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.root {
  position: relative;
  display: inline-flex;
  width: 100%;
  border: 1px solid var(--system-borders);
  border-radius: 6px;

  &Error input {
    border: 1px solid var(--error),
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--body);
}

.picker {
  min-width: 45px;
  min-height: 45px;
  cursor: pointer;
  border-radius: 6px 0 0 6px;
}

.input {
  width: 100%;
}

.input input {
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--body);
  border-radius: 8px;
  border: none;
  outline: none;
}

.error {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--error);
}
