.root {
  padding: 0 90px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}
