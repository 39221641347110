.title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body);
}

.hint {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--subtitle);
}

.root {
  padding: 24px;
  border: 1px dashed var(--system-borders);
  border-radius: 6px;

  &Error {
    border: 1px dashed var(--error),
  }

  &DragActive {
    background-color: var(--secondary);
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-bottom: 14px;

    svg {
      color: var(--primary);
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .icon {
    margin: 38px 0 12px;
  }
}

.button {
  min-width: 120px;
}

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--primary);
}

.error {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--error);
}
