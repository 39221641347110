.root {
  height: 100vh;
  display: flex;

  .right {
    display: flex;
    color: var(--body);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    .content {
      padding-top: 25vh;
      width: 100%;
      max-width: 346px;
      display: flex;
      flex-direction: column;
      flex: 1;

      a {
        text-align: center;
        margin-bottom: 10px;
      }
      
      form {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--body);
        letter-spacing: -0.6px;
        margin: 220px 0 32px;
        text-align: center;
      }
    }
  }

  .left {
    position: relative;
    background: var(--secondary);
    width: 50%;
    padding: 60px 0 0 50px;
    overflow: hidden;

    .bigLogo {
      position: absolute;
      top: 50%;
      height: 80vh;
      transform: translateY(-50%);
      right: -40vh;

      svg {
        height: 100%;
        width: auto;
      }
    }
  }
}
