.root {
  display: flex;
  flex-direction: column;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 24px;
  }

  .inputWrapper {
    input {
      color: var(--body-light);
    }
  }
}
