.item {
  display: flex;
  align-items: center;
  gap: 24px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 240px;

    .name {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--body);
      word-break: break-word;
    }

    .email {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--body-light);
      word-break: break-word;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-grow: 1;

    .wrapper {
      display: flex;
      gap: 24px;
    }

    .status {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .icon {
      padding: 0;
      background-color: transparent;
    }
  }
}
