.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 34px 114px 34px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    .amount {
      display: flex;
      gap: 4px;

      .select {
        padding-top: 28px;
        min-width: 130px;
      }
    }

  }

  .asyncSelect {
    svg {
      cursor: pointer;
    }
  }

  .inactiveSelect {
    opacity: 0.5;
  }

  .buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-end;
  }

}
