.root {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    margin-top: 28px;
  }

  .title {
    color: var(--headings1);
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;

    .search {
      flex-basis: 380px;
    }

    .filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      .label {
        display: flex;
        align-items: center;
        gap: 8px;
        line-height: 28px;
        color: var(--body-light);

        & > svg {
          color: inherit;
        }
      }

      .select {
        width: 240px;
      }
    }
  }

  .content {
    display: flex;
    grid-gap: 24px;
    margin: 24px 0;
  }
}
