.root {
  background-color: var(--system-borders);
}

.horizontal {
  width: 100%;
  height: 1px;
  margin: 24px 0;
}

.vertical {
  width: 1px;
  min-height: 24px;
  margin: 0 24px;
}
