.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 8px;
  padding: 0 82px;

  .wrapper {
    width: 100%;

    .dropZone {
      padding-bottom: 60px;
    }

    svg {
      color: var(--primary);
    }
  }
}
