.root {
  display: flex;
  gap: 16px;
  padding-left: 14px;
  align-items: center;

  svg {
    color: var(--primary);
  }

  .tooltip {

    p {
      left: -50%;

      &::after {
        left: 80px;
      }
    }
  }

}
