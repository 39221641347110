.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin: 50px 0 26px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  color: var(--headings1);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
