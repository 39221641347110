.root {
  padding: 0 90px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--body);
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  width: 100%;

  @media (min-width: 1100px) {
    width: calc((100% - 24px) / 2);
  }
}