.root {
  padding: 0 90px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--headings2);
}

.text {
  margin-top: 8px;
  line-height: 28px;
  color: var(--subtitle);
}
