.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
}

.link {
  display: flex;
  gap: 10px;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--body);
  border-radius: 6px;
  text-decoration: none;
  border: 1px solid transparent;
  transition: background-color 300ms ease;

  &:hover {
    background-color: var(--primary-light);
  }
}

.disabled {
  pointer-events: none;
}

.active {
  color: var(--primary);
  background-color: var(--primary-light);
  border: 1px solid var(--primary);

  & svg {
    color: var(--primary);
  }
}
