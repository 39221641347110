.Toastify__toast {
  padding: 12px 16px;
  border-radius: 8px;

  &--success {
    background-color: var(--status-active);
  }

  &--error {
    background-color: var(--error);
  }

  &-container {
    width: 396px;
  }

  &-body {
    align-items: flex-start;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--system-background-light);
  }

  &-icon {
    width: 40px;
    height: 40px;
    margin-right: 12px;

    & + div {
      align-self: center;
      max-width: 260px;
    }
  }
}

.Toastify__close-button {
  opacity: 1;

  & > svg {
    width: 22px;
    height: 22px;
    fill: var(--system-background-light);
    stroke-width: 5px;
  }
}
