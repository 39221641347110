.title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--body);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.item {
  display: flex;
  align-items: center;
  gap: 24px;

  & > p {
    min-width: 240px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  & > div {
    flex-grow: 1;
  }

  .logo {
    width: 74px;
    height: 74px;
  }

  .color {
    display: flex;
    align-items: center;
    gap: 12px;

    &Item {
      width: 34px;
      height: 34px;
      border-radius: 6px;
    }

    &Text {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: var(--body);
    }
  }
}
