.root {
  display: flex;
  flex-direction: column;
  min-height: 218px;

  .emptyCard {
    flex: 1;
    border: 1px solid var(--system-borders);
    border-radius: 8px;
  }

  .panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .header {
      display: flex;
      justify-content: center;
      padding: 50px 0;
      border-radius: 8px 8px 0 0;
      background: var(--secondary);

        svg {
          color: var(--primary);
        }
    }

  }

  .title {
    padding: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--body);
  }

  .navLink {
    margin: 0;
    flex: 1;
    display: flex;
    text-decoration: unset;
    cursor: pointer;
  }

}

