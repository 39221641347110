.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 30px 24px 34px;
  }

  .title {
    padding-left: 90px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 88px 0;
  }

  .newPassword {
    margin-top: 22px;
    grid-column-start: 1;
  }

  .repeatNewPassword {
    margin-top: 22px;
    grid-column-start: 2;
  }

  .passwordRuleWrapper {
    grid-column: 1;
  }

}
