.root {
  display: flex;
  flex-direction: column;

  .panel {
    padding: 34px 114px 34px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .asyncSelect {
    svg {
      cursor: pointer;
    }
  }

  .inactiveSelect {
    opacity: 0.5;
  }
}
