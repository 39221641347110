.root {
  @media (min-width: 1300px) {
    flex: 1 0 434px;
  }
}

.separator {
  margin: 34px 0;
}

.colors {
  &Title {
    margin-bottom: 16px;
    color: var(--body);
  }

  &Pickers {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
