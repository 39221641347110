.root {

  .title {
    margin: 34px 0 22px;
    font-size: 24px;
    color: var(--headings1);
  }

  .breadcrumbs {
    margin-bottom: 36px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .sidebar {
    padding-left: 16px;

    ul {
      flex-direction: row;
      a {
        color: var(--body-light);
        line-height: 36px;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .active {
      border: none;
      border-radius: unset;
      background: none;
      border-bottom: 2px solid var(--primary);
    }
  }


}

.content {
  flex-grow: 1;
}
