.root {
  display: flex;
  align-items: center;
  padding-bottom: 30px;

  .errorPlace {
   flex: 1;
  }

  .description {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--body-light);
    flex: 1;
    div {
      display: flex;
    }
  }

}

