.root {
  padding: 0 60px;
}

.companyAdminRoot {
  padding: 0;
}

.panel {
  position: relative;

  .content {
    padding: 34px 24px;
  }
}

.title {
  margin: 24px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--headings1);
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 90px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--headings2);

  svg {
    color: var(--headings2);
  }
}

.footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  padding: 0 90px;

  .button {
    width: 120px;
  }
}
