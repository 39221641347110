.label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
