.root {
  display: flex;
  flex-direction: column;
  min-height: 600px;

  .description {
    margin-top: 26px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .actions {
      display: flex;
      margin-top: auto;
      gap: 10px;
      justify-content: space-between;

      & > button {
        width: 120px;
      }
    }
  }
}
