.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  .input {
    flex-basis: 380px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .label {
      display: flex;
      align-items: center;
      gap: 8px;
      line-height: 28px;
      color: var(--body-light);

      & > svg {
        color: inherit;
      }
    }

    .select {
      width: 240px;
    }
  }
}