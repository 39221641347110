.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 22px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--body);

    .subTitle {
      font-weight: 400;
      padding-top: 14px;
      color: var(--body);
    }

    svg {
      margin-right: 10px;
    }
  }

  .delete {
    display: flex;
    justify-content: flex-end;

    svg {
      color: var(--primary);
    }
  }

  h4 {
    display: flex;
    align-items: center;
  }

  .badge {
    margin-bottom: 16px;
  }
}
