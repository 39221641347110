.root {
  position: relative;
  display: inline-block;

  &:hover .hint {
    visibility: visible;
    opacity: 1;
  }

  .hint {
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 125%;
    width: fit-content;
    padding: 6px 10px;
    font-size: 12px;
    color: var(--primary);
    white-space: nowrap;
    text-align: center;
    background-color: var(--system-background-light);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    opacity: 0;
    z-index: 1;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    &.tooltipBelow {
      top: 125%;
      bottom: auto;
      &::after {
        bottom: 100%;
        top: auto;
        border-color: transparent transparent var(--system-background-light) transparent;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: var(--system-background-light) transparent transparent transparent;
    }
  }
}
