.root {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 880px;
  padding: 22px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  transform: translate(-50%, -50%);
  height: fit-content;
  margin-top: 50px;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--body);
  }

  .content {

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 600;
      color: var(--body);
      padding-left: 50px;
      gap: 10px;

      div {
        flex: 1;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 22px;
      overflow: auto;
      max-height: calc(100vh - 366px);
      padding: 0 2px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    margin-top: 24px;
    justify-content: space-between;

    .progressWrapper {
      display: flex;
      gap: 16px;
      align-items: center;

      .progressBar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background:
          radial-gradient(closest-side, white 79%, transparent 80% ),
          conic-gradient(from 0deg, var(--status-active) var(--percentage, 100%), var(--system-borders) 0);
      }

    }

    .buttons {
      display: flex;
      gap: 10px;
      min-width: 250px;

      & > button {
        flex-grow: 1;
        flex-basis: 50%;
      }
    }

  }
}
