.root {
  width: 100%;
  min-height: 100vh;

  .content {
    display: flex;
    height: 100%;
    min-height: 100vh;

    .main {
      flex: 1;
      overflow: hidden;
      padding: 24px;
    }
  }
}
