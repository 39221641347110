.item {
  display: flex;
  align-items: center;
  gap: 24px;

  & > h3 {
    min-width: 240px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--body-light);
  }

  & > p {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--body);
    word-break: break-word;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-grow: 1;

    .wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .info {
      padding: 0;
      background-color: transparent;
    }
  }
}
