aside {
  position: sticky;
  top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 90px);
  padding: 28px 24px 24px 24px;
  background-color: var(--system-background-light);

  .links {
    display: flex;
    flex-direction: column;
    gap: 30px;

    svg {
      color: var(--body-light);
    }

    .active {
      svg {
        color: var(--primary);
      }
    }
  }

  .logout {
    background: transparent;
    padding: 0;
  }
}

.initiator {
  top: 130px;
  height: calc(100vh - 130px);
}
