.root {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    display: block;
    width: 32px;
    height: 32px;
  }

  .status {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--body);
  }
}
