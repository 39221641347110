.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  transition: all 300ms ease;

  & svg {
    display: block;
    width: 24px;
    height: 24px;
    transition: color 300ms ease;
    color: inherit;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.primary {
  background-color: var(--primary);
  color: var(--system-background-light);
  border-color: var(--primary);

  &:hover {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
  }

  &:disabled {
    background-color: var(--primary-disabled);
    border-color: var(--primary-disabled);
  }
}

.secondary, .action {
  background-color: var(--system-background-light);
  color: var(--primary);
  border-color: var(--primary);

  &:hover {
    background-color: var(--primary-light);
    color: var(--primary);
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--system-background);
    color: var(--body-light);
    border-color: var(--body-light);
    opacity: 0.3;
  }

  & svg {
    color: var(--primary);
  }

  &:hover svg {
    color: var(--primary);
  }

  &:disabled svg {
    color: var(--body-light);
  }
}

.cancel {
  background-color: var(--system-background);
  color: var(--body);
  border-color: var(--system-borders);

  &:hover {
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--system-background-light);
    color: var(--body-disabled);
    &:hover {
      border-color: var(--system-borders);
    }
  }

  & svg {
    color: var(--primary);
  }

  &:hover svg {
    color: var(--primary);
  }

  &:disabled svg {
    color: var(--body-light);
  }
}

.action {
  border-color: var(--color-border-white);

  &:hover {
    border-color: var(--primary);
  }
}

.error {
  background-color: var(--error);
  color: var(--system-background-light);
  border-color: var(--error);

  &:hover {
    background-color: var(--error-dark);
    border-color: var(--error-dark);
  }

  &:disabled {
    background-color: var(--body-light);
    border-color: var(--body-light);
    opacity: 0.3;
  }
}

.ghost {
  padding: 8px 4px;
  background-color: transparent;
  color: var(--primary);
  border-color: transparent;

  &:disabled {
    opacity: 0.6;
  }
}
