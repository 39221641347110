.root {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.warning {
  display: flex;
  gap: 12px;
  color: var(--warning);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 24px;
  svg {
    margin-top: 6px;
    color: var(--warning);
  }
}

.warningTitle {
  white-space: pre-line;
}
