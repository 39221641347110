.banner {
  background-color: var(--warning);
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 90px;
  left: 0;
  z-index: 10;
  width: 100%;
}

.button {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: (var(--body));
  margin-bottom: 10px;
  &:hover {
    color: (var(--body));
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--body-light);
  margin-right: 16px;
}
