.title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--body);
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 454px;
  padding: 24px;
  background-color: var(--system-background-light);
  border: 1px solid var(--system-borders);
  border-radius: 8px;
  transform: translate(-50%, -50%);
}

.content {
  height: 292px;
  display: flex;
  justify-content: center;
}

.image {
  display: block;
  width: 100%;
  height: 292px;
  object-fit: contain;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 11px;

  & > button {
    flex-basis: 50%;
  }
}
