.root {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    margin: 24px 0;
  }

}
