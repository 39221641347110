.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 90px;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    color: var(--headings2);
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--headings2);
}
