.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  padding: 0 90px;
}

.button {
  width: 110px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.edit {
  justify-content: flex-end;
}
