.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview {
  width: 94px;
  height: 94px;
}

.button {
  position: absolute;
  right: -12px;
  top: -12px;
}
