.root {
  display: flex;
  flex-direction: column;

  .progress {
    height: 8px;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: var(--system-borders);
    margin: 16px 0 6px;
  }

  .bar {
    display: flex;
    height: 8px;
    border-radius: 6px;
    background: var(--success);
    transition: width 300ms ease;
  }

  .red {
    background: var(--error);
  }

  .orange {
    background: var(--warning);
  }

  .labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: var(--body-light);
  }
}
