.root {
  display: flex;
  flex-direction: column;

  .formWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
